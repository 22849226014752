import styled from 'styled-components';
import {colors} from '../../../../components/resources/index'
import {makeStyles} from 'tss-react/mui';

export const Profit = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 150px;
  padding: 10px;
  position: relative;
  color: ${
          colors.blue
  };
  background-color: ${
          colors.white
  };

  &:hover {
    border: 1px solid ${colors.blue};
    cursor: pointer;
  }
`
export const Profit2 = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 150px;
  margin-top: 10px;
  position: relative;
  color: ${
          colors.blue
  };
  background-color: ${
          colors.white
  };

  &:hover {
    border: 1px solid ${colors.blue};
    cursor: pointer;
  }
`
export const Article = styled.article`
  position: relative;
  text-align: center;
  margin-top: 5px;
`
export const SpanDollar = styled.span`
  width: 100%;
  font-size: 45px;
  font-weight: bold;
`
export const SpanCash = styled.span`
  width: 100%;
  font-size: 45px;
  margin-left: 10px;
  font-weight: bold;
`
export const H4 = styled.h4`
  position: absolute;
  padding: 0;
  margin: 0 0 0 37px;
  font-size: 22px;
  font-weight: bold;
  color: ${colors.blue};
`
export const useStyles = makeStyles()(() => ({
    container: {
        padding: '10px',
        borderRadius: '10px',
        marginLeft: 'initial',
        backgroundColor: colors.blueTransparent
    }
}));