import styled from "styled-components";
import {makeStyles} from "tss-react/mui";
import {colors} from "../../../../components/resources/index";

export const Message = styled.section`
  width: 100%;
  min-height: 50px;
  background-color: ${colors.blueTransparent};
  margin-bottom: 35px;
  margin-top: 0;
  border-radius: 5px;
  padding: 12px;
  color: ${colors.blue};
  font-weight: bold;
  font-size: 20px;
`;

export const WrapperForm = styled.article`
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 25px;
`;

export const ArticleLink = styled.article`
  display: flex;
  justify-content: center;
  width: 100%;
`
export const DivContainer = styled.div`
  color: ${colors.blue};
  background-color: ${colors.blueTransparent};
  font-weight: bold;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
  padding: 20px;

  &:hover {
    background-color: ${colors.activeColor};
  }
`

export const DivImage = styled.div`
  float: left;
  margin-right: 5px;
`

export const DivLink = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 700px;
`

export const HelpText = styled.span`
  font-size: 14px;
  color: ${colors.gray2};
  text-align: center;
  width: 100%;
  margin-top: 0;
  display: inline-block;
  font-weight: 100;
`

export const useStyles = makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        marginTop: '20px',
        height: '40px',
        color: '#560e1a'
    },
    row: {
        boxShadow: '0 0 0 !important'
    },
}));
