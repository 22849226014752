import React, {useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid';


export function GridDocPlaceBackOffice(props) {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    useEffect(() => {
        if (rowSelectionModel) {
            if (props.callBackCheckBox) {
                props.callBackCheckBox(rowSelectionModel);
            }
        }
    }, [rowSelectionModel]);


    return (
        <DataGrid
            rows={props.data}
            columns={props.headColums}
            sortingOrder={['asc', 'desc']}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: props.numberRows,
                    },
                },
                sorting: {
                    sortModel: [{field: props.orderRowsBy, sort: props.orderRows}],
                },
            }}
            checkboxSelection={props.checkboxSelection}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
        />
    );
}

export default GridDocPlaceBackOffice;