import {LOGIN, LOGIN_CLEAR_DATA, LOGIN_ERROR, LOGIN_SUCCESS,} from "./actionType"

export const login = (user, navigate) => {
    return {
        type: LOGIN,
        payload: {user, navigate},
    }
}

export const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    }
}

export const loginError = error => {
    return {
        type: LOGIN_ERROR,
        payload: error,
    }
}

export const loginClearData = () => {
    return {
        type: LOGIN_CLEAR_DATA,
        payload: {}
    };
};