import React from "react";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Tooltip from "@mui/material/Tooltip";

import {useStyles, Wrapper} from "./style";


export function SearchDocPlaceBackOffice(props) {
    const [searchField, setSearchField] = React.useState("");
    const {classes} = useStyles();

    const searchItems = (term) => {
        props.callSearch(props.original ? term.trim() : term.toLowerCase().trim());
    };

    const searchValue = (e) => {
        setSearchField(e.target.value);
        searchItems(e.target.value);
        if (!e.target.value) {
        }
    }

    const clearSearch = () => {
        setSearchField("");
        searchItems(" ");
    }

    return (
        <Wrapper>
            <article className='baseSearch'>
                <FindInPageIcon className={classes.iconSearch}/>
                <input
                    type="text"
                    value={searchField}
                    onChange={searchValue}
                    className="form-control border-0 shadow-none"
                    placeholder="Search"
                />
                {searchField && (
                    <Tooltip title="clear search" placement="left">
                        <BackspaceIcon
                            onClick={clearSearch}
                            className={classes.buttonSearch}
                            alt="Delete"
                        />
                    </Tooltip>
                )}
            </article>
        </Wrapper>
    )
}

export default SearchDocPlaceBackOffice