import React, {useEffect, useState} from 'react'
import {Bar, BarChart, CartesianGrid, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {colors} from '../../../../components/resources/index'
import {customers} from "../../../../store/customers/actions";
import {Container} from "reactstrap";

const data = [
    {month: 'January', Revenue: 15},
    {month: 'February', Revenue: 1},
    {month: 'March', Revenue: 25},
    {month: 'April', Revenue: 9},
    {month: 'May', Revenue: 8},
    {month: 'June', Revenue: 7},
    {month: 'July', Revenue: 2},
    {month: 'August', Revenue: 3},
    {month: 'September', Revenue: 6},
    {month: 'October', Revenue: 1},
    {month: 'November', Revenue: 8},
    {month: 'December', Revenue: 5},
]

export function BarChartsBf() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataCustomers, setDataCustomers] = useState([]);

    const {responseCustomers} = useSelector((state) => ({
        errorCustomers: state.Customers.errorCustomers,
        responseCustomers: state.Customers.responseCustomers,
        loadingCustomers: state.Customers.loadinCustomers,
    }));

    useEffect(() => {
        if (responseCustomers && responseCustomers) {
            setDataCustomers(responseCustomers);
        }
    }, [responseCustomers]);

    useEffect(() => {
        if (!responseCustomers) {
            dispatch(customers({}, navigate))
        }
    }, [])

    return (
        <Container fluid>
            <ResponsiveContainer aspect={2.3}>
                <BarChart data={data}>
                    <CartesianGrid fill={colors.blueTransparent} strokeDasharray='7 1 1'/>
                    <XAxis dataKey='month' fontSize={'15px'}>
                        <Label
                            value="Total revenue"
                            offset={-4}
                            position="insideBottom"
                            fill={colors.blue}
                            fontWeight={'bold'}
                        />
                    </XAxis>
                    <YAxis/>
                    <Tooltip/>
                    <Bar dataKey='Revenue' fill={colors.blue}>
                        <LabelList dataKey="Revenue" position="top" fontSize={'15px'}/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Container>
    )
}

export default BarChartsBf