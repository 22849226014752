import {all, fork} from "redux-saga/effects"
import LoginSaga from './auth/sagas';
import Customers from './customers/sagas'

export default function* rootSaga() {
    yield all([
        fork(LoginSaga),
        fork(Customers)
    ])
}
