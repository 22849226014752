import React from 'react'
import {Outlet, useNavigate} from 'react-router-dom';
import {Text, useStyles, Version} from './style'
import logoDocPlace from '../../../../assets/logoDocPlace.svg';
import HeaderDocPlaceBf from '../Header/header';

import {styled, useTheme} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentIcon from '@mui/icons-material/Payment';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import {Box} from '@mui/material';

const drawerWidth = 240;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export function SidebarDocPlaceBf() {
    const {classes} = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [openMenu, setOpenMenu] = React.useState(true);


    const [activeOption, setActiveOption] = React.useState(
        {
            menu: 'Dashboard',
        }
    );
    const callMenuOption = (opt) => {
        setActiveOption({menu: opt.target.innerText});
        switch (opt.target.innerText) {
            case 'Dashboard': {
                navigate('/dashboard');
                break;
            }
            case 'Payment': {
                navigate('/payment');
                break;
            }
            case 'Customers': {
                navigate('/customer');
                break;
            }
        }
    }

    const handleClick = () => {
        setOpenMenu(!openMenu);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <HeaderDocPlaceBf/>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader className={classes.Bg}>
                    <img
                        src={logoDocPlace}
                        alt='DocPlace'
                        className={classes.logoDocPlace}
                    />
                    <Text>Back-Office</Text>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.color}/> :
                            <ChevronLeftIcon className={classes.color}/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    <ListItem
                        onClick={callMenuOption.bind(this)}
                        className={activeOption.menu === 'Dashboard' && classes.activeColor}
                        button key="dashboard"
                    >
                        <ListItemIcon className={classes.mW}>
                            <DashboardIcon className={classes.colorBlue}/>
                        </ListItemIcon>
                        <ListItemText className={classes.colorBlue} primary="Dashboard"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem
                        onClick={callMenuOption.bind(this)}
                        className={activeOption.menu === 'Customers' && classes.activeColor}
                        button key="customer"
                    >
                        <ListItemIcon className={classes.mW}>
                            <SwitchAccountIcon className={classes.colorBlue}/>
                        </ListItemIcon>
                        <ListItemText className={classes.colorBlue} primary="Customers"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem
                        onClick={callMenuOption.bind(this)}
                        className={activeOption.menu === 'Payment' && classes.activeColor}
                        button key="payment"
                    >
                        <ListItemIcon className={classes.mW}>
                            <PaymentIcon className={classes.colorBlue}/>
                        </ListItemIcon>
                        <ListItemText className={classes.colorBlue} primary="Payment"/>
                    </ListItem>
                </List>
                <Version>
                    Version: 1.0.7
                </Version>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                <Outlet/>
            </Box>
                        
        </Box>
    );
}

export default SidebarDocPlaceBf
