import React, {useEffect} from 'react'
import SidebarDocPlaceBf from './Components/Sidebar/sidebar'
import {useNavigate} from "react-router-dom";

const MasterPage = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("docplaceBackoffice")) {
            navigate("/login");
        }
    }, []);


    return (
        <div>
            <SidebarDocPlaceBf/>
        </div>
    );
}
export default MasterPage;
