import styled from "styled-components";
import {colors} from "../../components/resources/index";

export const TextTitle = styled.p`
  width: 350px;
  text-align: center;
  background-color: ${colors.blueTransparent};
  margin-top: 0;
  border-radius: 5px;
  padding: 10px;
  color: ${colors.blue};
  font-weight: bold;
  font-size: 15px;
`;

export const TextButton = styled.span`
  margin-left: 5px;
`
