import {call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";
import {CUSTOMERS_CLEAR_DATA, CUSTOMERS_LIST} from "./actionType";
import {getCustomers} from "../../helpers/call_apis";
import {customerError, customersSuccess} from "./actions";


function* GetCustomersList({payload: {customer, navigate}}) {
    try {
        const response = yield call(getCustomers, customer);
        let newResponse = [];
        response?.data?.map((customer, i) => {
            newResponse.push({...customer, id: i})
        })
        yield put(customersSuccess(newResponse));
    } catch (error) {
        if (error?.response?.status) {
            if (error.response.status === 401) {
                navigate("/login");
            }
        }
        toast.error(error?.response?.data?.error?.messages[0].message);
        yield put(customerError(error));
    }
}

function* customersClearData({payload: {}}) {
    try {
        yield put(customersClearData(true))
    } catch (error) {
        yield put(customerError(error))
    }
}

function* authSaga() {
    yield takeEvery(CUSTOMERS_LIST, GetCustomersList);
    yield takeEvery(CUSTOMERS_CLEAR_DATA, customersClearData);
}

export default authSaga