/* Login page
list API's*/
export const POST_LOGIN = "/security/api/v1.0/Account/SignIn";
export const FORGET_PASSWORD = "/billing/Api/V1/Public/SendResetPasswordLink";  //forgot password
export const RESET_PASSWORD = "/billing/Api/V1/Public/ResetPasswordHash";   //reset password with hash
/* Login page
END*/

/* Customers page
list API's*/
export const GET_CUSTOMERS = '/security/api/v1.0/SystemUser/GetBillingCustomers';
/* Customers page
END's*/