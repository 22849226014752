import React from "react";
import TextField from "@mui/material/TextField";
import {useStyles} from './style'

const InputFormDocPlace = ({
                               field,
                               setField,
                               type,
                               label,
                               helperText,
                               expresionRegular,
                               required,
                               multiline,
                               rows,
                               id,
                               maxLength,
                               disabled
                           }) => {

    const classes = useStyles();

    const onChange = (e) => {
        setField({...field, value: e.target.value});
    }

    const validation = (e) => {
        if (expresionRegular) {
            if (expresionRegular.test(field.value)) {
                setField({...field, error: false});
            } else {
                setField({...field, error: true});
            }
        } else if (required) {
            if (field.value) {
                setField({...field, error: false});
            } else {
                setField({...field, error: true});
            }
        }
    }

    return (
        <TextField
            error={field.error}
            disabled={disabled}
            required={required}
            className={classes.inputForm}
            value={field.value}
            helperText={helperText}
            id={id}
            type={type}
            multiline={multiline}
            rows={rows}
            label={label}
            variant="outlined"
            onChange={onChange}
            onKeyUp={validation.bind(this)}
            onBlur={validation}
            inputProps={{maxLength: maxLength}}
        />
    )
}

export default InputFormDocPlace