import React, {useEffect, useState} from 'react'
import {Container} from "reactstrap";
import {Card, CardContainer, ContainerItems, Data, IconDiv, Text} from "./style";
import {Grid} from "@mui/material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

export function Cards(props) {
    let [totalCustomers, setTotalCustomers] = useState(0);
    let [totalNumberLicenses, setTotalNumberLicenses] = useState(0);
    let [totalLicensesInUse, setTotalLicensesInUse] = useState(0);

    useEffect(() => {

        if (props.billingInformation.length > 0) {
            const numberLicenses = props.billingInformation.reduce((acumulador, row) => {
                const maxUsersCount = row.maxUsersCountSetting === 'null' ? 0 : parseInt(row.maxUsersCountSetting);
                return acumulador + (isNaN(maxUsersCount) ? 0 : maxUsersCount);
            }, 0);
            setTotalNumberLicenses(numberLicenses);

            const licensesInUse =  props.billingInformation.reduce((acumulador, row) => {
                return acumulador + row.noLicensesInUse;
            }, 0);
            setTotalLicensesInUse(licensesInUse)

            const customers =  props.billingInformation.reduce((acumulador, row) => {
                return acumulador + 1;
            }, 0);
            setTotalCustomers(customers);
        }
    }, [props.billingInformation]);

    return (
        <Container fluid>
            <CardContainer>
                <Grid container spacing={1.5}>
                    <Grid item xs={3}>
                        <Card>
                            <ContainerItems>
                                <IconDiv>
                                    <PeopleAltIcon style={{fontSize: 60}}/>
                                </IconDiv>
                                <Text>Number of customers</Text>
                                <Data>{totalCustomers}</Data>
                            </ContainerItems>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card>
                            <ContainerItems>
                                <IconDiv>
                                    <PlaylistAddCircleIcon style={{fontSize: 60}}/>
                                </IconDiv>
                                <Text>Number of licenses</Text>
                                <Data>{totalNumberLicenses}</Data>
                            </ContainerItems>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card>
                            <ContainerItems>
                                <IconDiv>
                                    <PlaylistRemoveIcon style={{fontSize: 60}}/>
                                </IconDiv>
                                <Text>Licenses to expire this month</Text>
                                <Data>0</Data>
                            </ContainerItems>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card>
                            <ContainerItems>
                                <IconDiv>
                                    <PlaylistAddCheckIcon style={{fontSize: 60}}/>
                                </IconDiv>
                                <Text>Number of active users</Text>
                                <Data>{totalLicensesInUse}</Data>
                            </ContainerItems>
                        </Card>
                    </Grid>
                </Grid>
            </CardContainer>
        </Container>
    )
}

export default Cards