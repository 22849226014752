import {get, post} from './apis';
import * as url from './url';

/* Page login
list API's*/
const postLogin = (data) => post(url.POST_LOGIN, data);
//forget password
const postForgetPassword = (data) => post(url.FORGET_PASSWORD, data);
//resetPassword
const postResetPassword = (data) => post(url.RESET_PASSWORD, data);
/* Page login
END*/
/* Customers page
list API's*/
const getCustomers = () => get(`${url.GET_CUSTOMERS}?fullInfo=true`)
export {
    //Auth
    postLogin,
    //forget password
    postForgetPassword,
    //reset password
    postResetPassword,
    //Customers
    getCustomers
};
