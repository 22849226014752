import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {login,loginClearData} from '../../store/auth/actions';
import MetaTags from "react-meta-tags";
import {Container} from "reactstrap";
import {LoginFormDocPlace, useStyles, Wrapper, WrapperFormDocPlace, WrapperLogin, ResendCodeContent,
    ResendCodeContentOpt,
    ResendCodeTitle,
    ResendCodeWrapper} from "./style";
import {toast, ToastContainer} from "react-toastify";
import logoFD from '../../assets/logoColorsDocPlace.png';
import InputDocPlace from "../../components/form/input";
import LoadingComponent from "../../components/loading/loading";
import {expressions} from "../../components/resources";
import Button from "@mui/material/Button";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import {useDispatch, useSelector} from "react-redux";
import http from "../../services/http-common";

const Login = () => {
    const {classes} = useStyles();
    const notify = (msj, event) => toast[event](msj);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userName, setUserName] = useState({value: '', error: false});
    const [password, setPassword] = useState({value: '', error: false});

    const [mfaId, setMfaId] = useState("");
    const [mfaIsRequired, setMfaIsRequired] = useState(false);
    const [code, setCode] = useState({ value: "", error: false });

    const [showLogin, setShowLogin] = useState(false);

    const {errorLogin, responseLogin, loadingLogin} = useSelector(state => ({
        errorLogin: state.Login.errorLogin,
        responseLogin: state.Login.responseLogin,
        loadingLogin: state.Login.loadingLogin
    }))


    const setLogin = (e) => {
        e.preventDefault();
        if (!userName.error && !password.error) {
            if ((userName.value.indexOf('thenetworkplace.com') >= 0)) {
                let obj = {
                    email: userName.value,
                    password: password.value
                }
                dispatch(login(obj, navigate));
            } else {
                notify('User or Password is invalid!', 'error');
            }

        } else {
            notify('Please enter all the required information', 'error');
        }
    }

    useEffect(() => {
        if (responseLogin) {
            if (responseLogin.mfaRequired) {
                setMfaId(responseLogin?.mfaId);
                setMfaIsRequired(responseLogin?.mfaRequired);
            }else {
                if(responseLogin.superUser === 1){
                    localStorage.setItem("docplaceBackoffice", JSON.stringify(responseLogin));
                    navigate('/dashboard');
                    dispatch(loginClearData());
                }else{
                    notify("You don't have a Super User", 'error');
                }
            }
            
        }
    }, [responseLogin]);

    useEffect(() => {
        if (errorLogin) {
            notify(errorLogin?.response?.data?.error?.messages[0]?.message, 'error');
        }
    }, [errorLogin]);

    const sendCode = () => {
        setShowLogin(true);
        let body = {
          mfaId: mfaId,
          code: code.value,
        };
    
        http
          .post(
            `/security/api/v1.0/Account/MfaVerfication`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response) {
                setShowLogin(false);
                if(response.data.superUser === 1){
                    localStorage.setItem("docplaceBackoffice", JSON.stringify(response.data));
                    navigate('/dashboard');
                    dispatch(loginClearData());
                }else{
                    notify("You don't have a Super User", 'error');
                }
            } else {
              notify("The activation link you used has already expired", "error");
            }
          })
          .catch((err) => {
            setShowLogin(false);
            notify(err.response?.data?.error?.messages[0].message, "error");
          });
      };

      const resendCodeEmail = (type) => {
        setShowLogin(true);
        let body = {
          mfaId: mfaId,
          sendBy: type,
        };
    
        setShowLogin(true);
    
        http
          .post(
            `/security/api/v1.0/Account/MfaResend`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setShowLogin(false);
            if (response) {
              setMfaId(response.data.mfaId);
              notify("New Code sended", "success");
            }
    
            setShowLogin(false);
          })
          .catch((err) => {
            setShowLogin(false);
            setShowLogin(false);
            notify(err.response?.data?.error?.messages[0].message, "error");
          });
      };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login</title>
            </MetaTags>
            <div>
                <Container fluid className={classes.clear}>
                    <ToastContainer autoClose={3000}/>
                    <LoadingComponent
                        show={loadingLogin || showLogin}
                        text={"Login to Back Office DocPlace..."}
                    />
                    <Wrapper>
                        <WrapperLogin>
                            <img alt="docplace" className={classes.imgLogo} src={logoFD}/>
                            <LoginFormDocPlace onSubmit={setLogin}>
                                <WrapperFormDocPlace>
                                    {
                                        mfaIsRequired ?
                                            <>
                                                <InputDocPlace
                                                id={"code"}
                                                field={code}
                                                setField={setCode}
                                                required={true}
                                                type="text"
                                                label="Code"
                                                />
                                                <div>
                                                <>
                        <Button
                          onClick={sendCode}
                          className={classes.buttons}
                          variant="contained"
                          style={{ marginBottom: "100px" }}
                          color="success"
                        >
                          Enter Code
                        </Button>
                        <ResendCodeWrapper>
                          <ResendCodeTitle>
                            Resend code verification
                          </ResendCodeTitle>
                          <ResendCodeContent>
                            <ResendCodeContentOpt
                              onClick={resendCodeEmail.bind(this, "EMAIL")}
                            >
                              <MarkEmailReadIcon style={{ color: "#006ac6" }} />{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "4px 0 0 12px",
                                  position: "absolute",
                                }}
                              >
                                Email
                              </span>
                            </ResendCodeContentOpt>
                            <ResendCodeContentOpt
                              onClick={resendCodeEmail.bind(this, "PHONE")}
                            >
                              <MarkUnreadChatAltIcon
                                style={{ color: "#006ac6" }}
                              />{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0px 0 0 12px",
                                  position: "absolute",
                                }}
                              >
                                {" "}
                                Send a text message{" "}
                              </span>
                            </ResendCodeContentOpt>
                          </ResendCodeContent>
                        </ResendCodeWrapper>
                      </>
                                                </div>
                                            </>
                                        : 
                                        <>
                                                <InputDocPlace
                                                id={'username'}
                                                field={userName}
                                                setField={setUserName}
                                                required={true}
                                                type='text'
                                                label="Username"
                                                expresionRegular={expressions.email}
                                            />
                                            <InputDocPlace
                                                id={'password'}
                                                field={password}
                                                setField={setPassword}
                                                required={true}
                                                type='password'
                                                label="Password"
                                            />
                                            {
                                                <section>
                                                    <Button type="submit" className={classes.buttons} variant="contained"
                                                            color="success">
                                                        Sign in
                                                    </Button>
                                                </section>
                                            }
                                        </>
                                    }
                                    
                                </WrapperFormDocPlace>
                            </LoginFormDocPlace>

                        </WrapperLogin>
                    </Wrapper>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Login
