import styled from 'styled-components';

export const WrapperDasboard = styled.div`
  width: 100%;
  height: 320px;
  overflow-y: auto;
  border-radius: 4px;
  margin-top: 20px;
  display: inline-block;
  margin-bottom: 15px;
`;