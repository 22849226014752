import styled from 'styled-components';
import {makeStyles} from "tss-react/mui";

export const Wrapper = styled.div`
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 25px;
`;

export const useStyles = makeStyles()(() => ({
    iconSearch: {
        width: '28px',
        height: '28px',
        margin: '15px 16px 0',
        fontSize: '25px',
        position: 'absolute',
        color: '#006ac6'
    },
    buttonSearch: {
        float: 'right',
        marginTop: '-41px !important',
        marginRight: '16px !important',
        color: 'rgb(251, 167, 48)',
    },
    input: {
        backgroundColor: 'red',
        height: '40px',
        paddingTop: '18px'
    },
    baseSearch: {
        width: '100%',
        backgroundColor: 'red',
        borderRadius: '4px',
        position: 'relative',
        height: '58px'
    }

}));