import React from "react"
import LoadingScreen from 'react-loading-screen';
import {colors} from '../resources/index';

const Loading = (props) => {
    return (
        <LoadingScreen
            loading={props.show}
            bgColor={colors.whiteTransparent}
            spinnerColor={colors.blue}
            textColor={colors.blue}
            text={props.text}
        >
            <div></div>
        </LoadingScreen>
    )
}

export default Loading