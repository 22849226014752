import styled from 'styled-components';
import {makeStyles} from 'tss-react/mui';
import {colors} from '../../../../components/resources/index';


export const CardContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  height: 175px;
  position: relative;
  color: ${
          colors.blue
  };
  background-color: ${
          colors.blueTransparent
  };
`;
export const Card = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 145px;
  position: relative;
  color: ${
          colors.blue
  };
  background-color: ${
          colors.white
  };

  &:hover {
    border: 1px solid ${colors.blue};
    cursor: pointer;
`;

export const Data = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  margin: 10px 0 0 70px;
`
export const Text = styled.p`
  font-size: 18px;
  width: auto;
  font-weight: bold;
  line-height: 1;
  margin: 20px 0 0 70px;
  position: static;
`;

export const IconDiv = styled.div`
  position: absolute;
  margin: 18px 0 0 0;
`

export const ContainerItems = styled.div`
  padding: 25px;
`


export const useStyles = makeStyles()(() => ({
    wrapperOverview: {
        display: 'inline-block',
        width: '100%',
        marginTop: '20px'
    }
}));
