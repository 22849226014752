import {call, put, takeEvery} from "redux-saga/effects"
import {LOGIN, LOGIN_CLEAR_DATA} from "./actionType"
import {loginError, loginSuccess} from "./actions"
import {postLogin,} from "../../helpers/call_apis"


function* login({payload: {user, navigate}}) {
    try {
        const response = yield call(postLogin, user)
        yield put(loginSuccess(response))
    } catch (error) {
        yield put(loginError(error));
        navigate('/login');
    }
}

function* loginClearData({payload: {}}) {
    try {
        yield put(loginClearData(true))
    } catch (error) {
        yield put(loginError(error))
    }
}

function* authSaga() {
    yield takeEvery(LOGIN, login);
    yield takeEvery(LOGIN_CLEAR_DATA, loginClearData);
}

export default authSaga
