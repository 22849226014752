import React, { useEffect } from "react";
import {
    Wrapper,
    Title,
    ViewWraper,
    ViewUL,
    ViewLI,
    TextView,
    useStyles
} from './style';

export function TitleDocPlaceBf(props) {
    const classes = useStyles();
    const [title, setTitle] = React.useState('Explorer');
    const [viewList, setViewList] = React.useState(false);
    const iconGrid = classes.iconSize + " btn-icon mr-2 fas fa-th-large";
    const iconList = classes.iconSize + " btn-icon mr-2 fas fa-th-list";

    const changeView = () => {
        setViewList(!viewList);
    };

    useEffect(() => {
        if(props.title){
            setTitle(props.title);
        }
    }, [props.title])

    return (
        <Wrapper>
            <Title>{title}</Title>
            {
                props.viewActive
                    ?
                    <ViewWraper>
                        <ViewUL>
                            <TextView>View</TextView>
                            <ViewLI onClick={changeView} className={viewList ? classes.modeInactive : classes.modeActive}>
                                <span className={iconGrid}/>
                            </ViewLI>
                            <ViewLI onClick={changeView} className={viewList ? classes.modeActive : classes.modeInactive}>
                                <span className={iconList}/>
                            </ViewLI>

                        </ViewUL>
                    </ViewWraper>
                    : null
            }
        </Wrapper>
    )
}

export default TitleDocPlaceBf
