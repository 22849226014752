import React, {useEffect, useState} from 'react'
import MetaTags from "react-meta-tags";
import {Container} from "reactstrap";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import SearchDocPlaceBackOffice from './components/search/search'
import LoadingComponent from "../../components/loading/loading";
import TitleDocPlaceBf from "../../components/MastePage/Components/title/title";
import GridDocPlaceBackOffice from '../../components/grid/grid';
import {customers} from '../../store/customers/actions'
import {WrapperCustomer} from "./style";

const headColCustumer = [
    {
        field: "customerId",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Customer id",
    },
    {
        field: "companyName",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Company name",
    },
    {
        field: "email",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Email account",
    },
    {
        field: "plan",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Plan name",
    },
    {
        field: "maxUsersCountSetting",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Number of licenses",
    },
    {
        field: "noLicensesInUse",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Licenses used",
    },
    {
        field: "Last date of payment",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Last date of payment",
    },
    {
        field: "Payment method",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Payment method",
    },
];

export function CustomerDocPlaceBf() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataCustomers, setDataCustomers] = useState([]);
    const [docTypesAPI, setDocTypesAPI] = useState([]);

    const {errorCustomers, responseCustomers, loadingCustomers} = useSelector((state) => ({
        errorCustomers: state.Customers.errorCustomers,
        responseCustomers: state.Customers.responseCustomers,
        loadingCustomers: state.Customers.loadingCustomers,
    }));

    const findDocumentTypes = (term) => {
        setDataCustomers(term
            ? docTypesAPI.filter(doc => (doc.customerId).toLowerCase().includes(term))
            : docTypesAPI);
    }

    const searchFile = (filterSearch) => {
        findDocumentTypes(filterSearch);
    }

    useEffect(() => {
        let newObject = []
        if (responseCustomers && responseCustomers) {
            responseCustomers?.map((customer, i) => {
                if (customer?.email) {
                    const maxUsersCountSetting = customer.maxUsersCountSetting === '' || customer.maxUsersCountSetting === 'null' ? '0' : customer.maxUsersCountSetting;
                    newObject.push({...customer, id: i, maxUsersCountSetting})
                }
            })
            setDataCustomers(newObject);
            setDocTypesAPI(newObject);
        }
    }, [responseCustomers]);

    useEffect(() => {
        if (!responseCustomers) {
            dispatch(customers({}, navigate))
        }
    }, [])

    return (<React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>DocPlace Customers Back Office</title>
            </MetaTags>
            <Container fluid>
                <TitleDocPlaceBf title={'Customers'} viewActive={false}/>
                <ToastContainer autoClose={3000}/>
                <LoadingComponent show={loadingCustomers} text={"Getting Customers..."}/>
                <SearchDocPlaceBackOffice
                    callSearch={searchFile.bind(this)}
                />
                <WrapperCustomer>
                    <GridDocPlaceBackOffice
                        orderRowsBy={'customerId'}
                        orderRows={'asc'}
                        data={dataCustomers ? dataCustomers : []}
                        headColums={headColCustumer}
                        numberRows={10}
                        checkboxSelection={false}
                    />
                </WrapperCustomer>
            </Container>
        </div>
    </React.Fragment>)
}

export default CustomerDocPlaceBf