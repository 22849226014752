import {CUSTOMERS_CLEAR_DATA, CUSTOMERS_ERROR, CUSTOMERS_LIST, CUSTOMERS_SUCCESS} from './actionType'

export const customersClearData = () => {
    return {
        type: CUSTOMERS_CLEAR_DATA,
        payload: {}
    };
};
export const customers = (user, navigate) => {
    return {
        type: CUSTOMERS_LIST,
        payload: {user, navigate}
    };
};

export const customersSuccess = (user) => {
    return {
        type: CUSTOMERS_SUCCESS,
        payload: user
    };
};

export const customerError = error => {
    return {
        type: CUSTOMERS_ERROR,
        payload: error
    }
}