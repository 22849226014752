import {CUSTOMERS_CLEAR_DATA, CUSTOMERS_ERROR, CUSTOMERS_LIST, CUSTOMERS_SUCCESS} from "./actionType";

const initialState = {
    errorCustomers: null,
    responseCustomers: null,
    loadingCustomers: false,
}

const Customers = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMERS_LIST:
            state = {
                ...state,
                errorCustomers: null,
                loadingCustomers: true
            }
            break
        case CUSTOMERS_SUCCESS:
            state = {
                errorCustomers: null,
                responseCustomers: action.payload,
                loadingCustomers: false
            }
            break
        case CUSTOMERS_ERROR:
            state = {
                ...state,
                errorCustomers: action.payload,
                responseCustomers: null,
                loadingCustomers: false,
            }
            break
        case CUSTOMERS_CLEAR_DATA:
            state = {
                ...state,
                errorCustomers: null,
                responseCustomers: null,
                loadingCustomers: false,
            }
            break
        default:
            state = {...state}
            break
    }
    return state
}

export default Customers