import React, {useEffect, useState} from 'react'
import {Container} from "reactstrap";
import GridDocPlaceBackOffice from "../../../../components/grid/grid";
import {WrapperDasboard} from './style'
import {GridColDef} from "@mui/x-data-grid";

const headColCustumer: GridColDef[] = [
    {
        field: "fullName",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Customer Name",
    },
    {
        field: "plan",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Plan Name",
    },
    {
        field: "maxUsersCountSetting",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Licenses in plan",
    },

    {
        field: "noLicensesInUse",
        flex: 1,
        headerClassName: "MuiTableHead-root",
        headerName: "Licenses used",
    },
];


export function CustomersGrid(props) {
    const [dataCustomers, setDataCustomers] = useState([]);

    const callBackCheckBox = (value) => {
        props.callBackCheckBox(value)
    }

    useEffect(() => {
        let newObject = []
        if (props.billingInformation && props.billingInformation) {
            props.billingInformation?.map((customer, i) => {
                if (customer?.email) {
                    const maxUsersCountSetting = customer.maxUsersCountSetting === '' || customer.maxUsersCountSetting === 'null' ? '0' : customer.maxUsersCountSetting;
                    newObject.push({...customer, id: i, maxUsersCountSetting})
                }
            })
            setDataCustomers(newObject);
        }
    }, [props.billingInformation]);

    return (
        <Container fluid>
            <WrapperDasboard>
                <GridDocPlaceBackOffice
                    callBackCheckBox={callBackCheckBox}
                    orderRowsBy={'fullName'}
                    orderRows={'asc'}
                    data={dataCustomers ? dataCustomers : []}
                    headColums={headColCustumer}
                    numberRows={4}
                    checkboxSelection={true}
                />
            </WrapperDasboard>
        </Container>
    )
}