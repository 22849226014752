import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {ArticleLink, DivContainer, DivImage, DivLink, HelpText, Message, WrapperForm} from "./style";
import LoadingComponent from "../../../../components/loading/loading";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import InputFormDocPlace from "../../../../components/form/input";
import SelectDropdownDocPlace from "../../../../components/form/selectDropdown";
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function NewURLDocPlace(props) {
    const [showNew, setShowNew] = useState(false);
    const [loading, setLoading] = useState({load: false, message: "Creating link payment!"});
    const [companyName, setCompanyName] = useState({value: "", error: false, disabled: false});
    const [paymentPeriod, setPaymentPeriod] = useState({value: "", error: false, disabled: false});
    const [pricePerLicense, setPricePerLicense] = useState({value: "", error: false, disabled: false});
    const [numberOfLicenses, setNumberOfLicenses] = useState({value: "", error: false, disabled: false});
    const [onboardingAmount, setOnboardingAmount] = useState({value: '0', error: false, disabled: false});
    const [informationToShowToTheCustomer, setInformationToShowToTheCustomer] = useState({
        value: "",
        error: false,
        disabled: false
    });
    const [showLink, setShowLink] = useState(false);
    const [listPaymentPeriod] = useState([
        {value: 'month', label: 'Monthly'},
        {value: 'year', label: 'Yearly'},
    ]);
    const notify = (msj, event) => toast[event](msj);

    const close = () => {
        clearForm();
        props.close();
    };

    const clearForm = () => {
        setCompanyName({value: "", error: false});
        setPaymentPeriod({value: "", error: false});
        setInformationToShowToTheCustomer({value: "", error: false});
        setNumberOfLicenses({value: "", error: false});
        setPricePerLicense({value: "", error: false});
        setOnboardingAmount({value: "", error: false});
        setShowLink(false);
    };

    const createLinkPayment = () => {
        const total = numberOfLicenses.value * pricePerLicense.value;
        const link = `&type=${paymentPeriod.value.value}&licenses=${numberOfLicenses.value}&onboarding=${onboardingAmount.value}&info=${informationToShowToTheCustomer.value}&customer=${companyName.value}&total=${total}`;
        const newLink = link.replace(/ /g, "%20");
        return `https://www.docplace.io/register?plan=2${newLink}`
    }

    const newObject = props.billingInformation?.map(company => {
        return {
            ...company,
            companyName: company?.companyName?.toLowerCase(),
            customerId: company?.customerId?.toLowerCase()
        }
    });

    const newAction = () => {
        const company = newObject.find(r =>
            r.companyName === companyName.value.toLowerCase() ||
            r.customerId === companyName.value.toLowerCase());
        if (!company) {
            if (!validateForm()) {
                createLinkPayment()
                showTheLink()
            }
        } else {
            notify("There is already a customer with this name", "error");
        }
    }

    const validateForm = () => {
        if (!companyName.value) {
            setCompanyName({...companyName, error: true})
        }
        if (!paymentPeriod.value) {
            setPaymentPeriod({...paymentPeriod, error: true})
        }
        if (!informationToShowToTheCustomer.value) {
            setInformationToShowToTheCustomer({...informationToShowToTheCustomer, error: true})
        }
        if (!numberOfLicenses.value) {
            setNumberOfLicenses({...numberOfLicenses, error: true})
        } else {
            if (isNaN(numberOfLicenses.value)) {
                setNumberOfLicenses({...numberOfLicenses, error: true})
                notify("Number of licenses not is a number!", "error");
            }
        }
        if (!pricePerLicense.value) {
            setPricePerLicense({...pricePerLicense, error: true})
        } else {
            if (isNaN(pricePerLicense.value)) {
                setPricePerLicense({...pricePerLicense, error: true})
                notify("Price per license not is a number!", "error");
            }
        }
        if (!onboardingAmount.value) {
            setOnboardingAmount({...onboardingAmount, error: true})
        } else {
            if (isNaN(onboardingAmount.value)) {
                setOnboardingAmount({...onboardingAmount, error: true})
                notify("Onboarding amount not is a number!", "error");
            }
        }
        if (
            companyName.error ||
            !companyName.value ||
            paymentPeriod.error ||
            !paymentPeriod.value ||
            informationToShowToTheCustomer.error ||
            !informationToShowToTheCustomer.value ||
            numberOfLicenses.error ||
            !numberOfLicenses.value ||
            pricePerLicense.error ||
            !pricePerLicense.value ||
            onboardingAmount.error ||
            !onboardingAmount.value
        ) {
            return notify("Please enter all the required information", "error");
        }
    };

    const copyToClipboard = (event) => {
        const text = event.target.innerText;
        navigator.clipboard.writeText(text)
            .then(() => {
                notify("Copied", "success");
            })
            .catch((error) => {
                notify("Could not copy", "error");
            });
    }

    const showTheLink = () => {
        setShowLink(true);
    };

    useEffect(() => {
        if (props) {
            setShowNew(props.show);
        }
    }, [props.show]);

    return (
        <Dialog
            fullScreen
            open={showNew}
            onClose={close}
            TransitionComponent={Transition}
        >
            <LoadingComponent
                show={loading.load}
                text={loading.message}
            />

            <AppBar sx={{position: "relative"}}>
                <Toolbar style={{backgroundColor: "#006ac6"}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={close}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">

                    </Typography>
                    <Button autoFocus color="inherit" onClick={newAction}>
                        Generate
                    </Button>
                    <ToastContainer/>
                </Toolbar>
            </AppBar>
            <article style={{margin: "30px"}}>
                <Message>To generate a payment link, all fields must be entered.</Message>
                <WrapperForm>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputFormDocPlace
                                id={"companyName"}
                                field={companyName}
                                setField={setCompanyName}
                                required={true}
                                type="text"
                                label="Company Name"
                                helperText="* Write the name of the company"
                                maxLength={40}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropdownDocPlace
                                id={"paymentPeriod"}
                                field={paymentPeriod}
                                setField={setPaymentPeriod}
                                required={true}
                                label="Payment Period"
                                helperText="* Select the Payment Period"
                                arrayList={listPaymentPeriod}
                                isMulti={false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputFormDocPlace
                                id={"pricePerLicense"}
                                field={pricePerLicense}
                                setField={setPricePerLicense}
                                required={true}
                                type="text"
                                label="Price per license"
                                helperText="* Write the Price per license"
                                maxLength={3}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputFormDocPlace
                                id={"numberOfLicenses"}
                                field={numberOfLicenses}
                                setField={setNumberOfLicenses}
                                required={true}
                                type="text"
                                label="Number of licenses"
                                helperText="* Write the Number of licenses"
                                maxLength={4}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputFormDocPlace
                                id={"onboardingAmount"}
                                field={onboardingAmount}
                                setField={setOnboardingAmount}
                                required={true}
                                type="text"
                                label="Onboarding amount"
                                helperText="* Write the Onboarding amount"
                                maxLength={5}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputFormDocPlace
                                id={"informationToShowToTheCustomer"}
                                field={informationToShowToTheCustomer}
                                setField={setInformationToShowToTheCustomer}
                                required={true}
                                type="text"
                                label="Information to show to the customer"
                                helperText="* Write the Information to show to the customer"
                                maxLength={40}
                            />
                        </Grid>
                    </Grid>
                </WrapperForm>
            </article>
            <ArticleLink>{
                showLink &&
                <DivContainer>
                    <div onClick={copyToClipboard}>
                        <DivImage>
                            <FileCopyIcon/>
                        </DivImage>
                        <DivLink>
                            {createLinkPayment()}
                        </DivLink>
                    </div>
                    <HelpText>To copy the link, move your mouse over the link and click.!</HelpText>
                </DivContainer>
            }
            </ArticleLink>
        </Dialog>
    );
}

export default NewURLDocPlace;
