import React, {Fragment} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css'
import MasterPage from './components/MastePage';
import Dashboard from './DocPlace/Dashboard/dashboard';
import Payment from './DocPlace/Payment/payment'
import Customer from './DocPlace/Customer/customer'
import Login from "./DocPlace/Login/login";

export default function App() {

    return (
        <Router>
            <Fragment>
                <Routes>
                    <Route element={<MasterPage/>}>
                        <Route exact path='/' element={<Dashboard/>}/>
                        <Route exact path='/Dashboard' element={<Dashboard/>}/>
                        <Route exact path='/Payment' element={<Payment/>}/>
                        <Route exact path='/Customer' element={<Customer/>}/>
                    </Route>
                    <Route exact path='/Login' element={<Login/>}/>
                </Routes>
            </Fragment>
        </Router>
    );
}
