import React, {useEffect, useState} from 'react';
import {Container} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@mui/material";

import {Title} from './style'
import {ToastContainer} from 'react-toastify';
import LoadingComponent from '../../components/loading/loading'
import {TotalProfitYear} from './Components/TotalProfit/TotalProfitYear'
import {BarChartsBf} from './Components/BarCharts/barCharts'
import {Cards} from './Components/cards/cards'
import {customers} from "../../store/customers/actions";
import {CustomersGrid} from "./Components/Customers/customers";

export function DashboardDocPlaceBf() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataCustomers, setDataCustomers] = useState([]);
    const [inititalCustomers, setInititalCustomers] = useState([]);

    const {responseCustomers, loadingCustomers} = useSelector((state) => ({
        errorCustomers: state.Customers.errorCustomers,
        responseCustomers: state.Customers.responseCustomers,
        loadingCustomers: state.Customers.loadingCustomers,
    }));

    const callBackCheckBox = (value) => {
        let newValue = []
        if (value.length > 0) {
            value.map((v) => {
                const filterData = inititalCustomers.filter(r => r.id === v);
                newValue.push(filterData[0]);
            })
            setDataCustomers(newValue);
        } else {
            setDataCustomers(inititalCustomers);
        }
    }

    useEffect(() => {
        if (responseCustomers && responseCustomers) {
            setDataCustomers(responseCustomers);
            setInititalCustomers(responseCustomers);
        }
    }, [responseCustomers]);

    useEffect(() => {
        if (!responseCustomers) {
            dispatch(customers({}, navigate))
        }
    }, []);

    return (<React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>DocPlace Dashboard Back Office</title>
            </MetaTags>
            <Container fluid>
                <Title>Dashboard</Title>
                <ToastContainer autoClose={3000}/>
                <LoadingComponent show={loadingCustomers} text={"Getting Dashboard..."}/>
                <Cards
                    billingInformation={dataCustomers ? dataCustomers : []}
                />
                <CustomersGrid
                    billingInformation={inititalCustomers ? inititalCustomers : []}
                    callBackCheckBox={callBackCheckBox}
                />
                <Grid container spacing={0}>
                    <Grid item xs={6} md={3}>
                        <TotalProfitYear/>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <BarChartsBf/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </React.Fragment>)
}

export default DashboardDocPlaceBf