import {LOGIN, LOGIN_CLEAR_DATA, LOGIN_ERROR, LOGIN_SUCCESS,} from "./actionType"

const initialState = {
    /* Page login
   list API's*/
    errorLogin: null,
    responseLogin: null,
    loadingLogin: false,
    /* Page login
    END*/
}

const login = (state = initialState, action) => {
    switch (action.type) {
        /* Page login
    list API's*/
        case LOGIN:
            state = {
                ...state,
                errorLogin: null,
                loadingLogin: true,
            }
            break
        case LOGIN_SUCCESS:
            state = {
                ...state,
                errorLogin: null,
                responseLogin: action.payload,
                loadingLogin: false,
            }
            break
        case LOGIN_ERROR:
            state = {
                ...state,
                errorLogin: action.payload,
                responseLogin: null,
                loadingLogin: false,
            }
            break
        case LOGIN_CLEAR_DATA:
            state = {
                ...state,
                errorLogin: null,
                responseLogin: null,
                loadingLogin: false,
            }
            break
        /* Page login
        END*/
        default:
            state = {...state}
            break
    }
    return state
}

export default login