import styled from 'styled-components';
import {colors} from '../../components/resources/index';


export const Title = styled.h1`
  color: ${colors.blue};
  padding: 0;
  font-weight: bold;
  font-size: 47px;
  margin-bottom: 25px;
  margin-left: 11px;
`;




