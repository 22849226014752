import React from 'react';
import {Article, H4, Profit, Profit2, SpanCash, SpanDollar, useStyles} from './style'
import {Container} from 'reactstrap';

export function TotalProfitYear() {
    const {classes} = useStyles();
    return (
        <Container fluid>
            <div className={classes.container}>
                <Profit>
                    <Article>
                        <SpanDollar>$</SpanDollar>
                        <SpanCash>8,000.00</SpanCash>
                    </Article>
                    <H4>Total revenue this year</H4>
                </Profit>
                <Profit2>
                    <Article>
                        <SpanDollar>$</SpanDollar>
                        <SpanCash>8,000.00</SpanCash>
                    </Article>
                    <H4>Total revenue this month</H4>
                </Profit2>
            </div>
        </Container>
    )
}

export default TotalProfitYear