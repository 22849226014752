import styled, {css} from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {colors} from "../resources";

export const GrupInput = styled.div`
  position: relative;
  z-index: 90;
  bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  font-weight: 700;
  margin: 0 0 1px 0;
  min-height: 40px;
  cursor: pointer;
  ${props => props.valido === 'false' && css`
    color: ${colors.error};
  `}
`;

export const Input = styled.input`
  width: 100%;
  background: #fff;
  border-radius: 3px;
  height: 35px;
  line-height: 45px;
  padding: 0 40px 0 10px;
  transition: .3s ease all;
  border: 1px solid #ced4da;

  &:focus {
    border: 1px solid ${colors.bordeInput};
    outline: none;
    box-shadow: 3px 0px 10px rgba(42, 181, 125, 0.4);
  }

  ${props => props.valido === 'true' && css`
    border: 1px solid #2AB57D;
  `}

  ${props => props.valido === 'false' && css`
    border: 1px solid ${colors.error} !important;
  `}
`;

export const IconValidation = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  bottom: 28px;
  z-index: 100;
  font-size: 16px;
  opacity: 0;

  ${props => props.valido === 'false' && css`
    opacity: 1;
    color: ${colors.error};
  `}

  ${props => props.valido === 'true' && css`
    opacity: 1;
    color: ${colors.success};
    bottom: 10px;
  `}
`;


export const MjsError = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: ${colors.error};
  margin-left: 0px;
  display: none;
  ${props => props.valido === 'true' && css`
    display: none;
  `}
  ${props => props.valido === 'false' && css`
    display: block;
  `}
`;

export const WrapperPercentage = styled.div`
  width: 100%;
  padding: 3px;
  border: 1px solid ${colors.blue};
  border-radius: 5px;
`;

export const Percentage = styled.div`
  background-color: ${colors.blue};
  height: 35px;
  border-radius: 5px;
`;

export const PercentageTxt = styled.div`
  width: 100%;
  font-size: 25px;
  color: ${colors.Title2};
  font-weight: bold;
`;

export const PercentageTxtinfo = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 100;
  line-height: 1;
  word-break: break-word;
  color: ${colors.Title2};
  margin-top: -3px;
  margin-bottom: 20px;
`;


export const useStyles = makeStyles((theme) => ({
    inputForm: {
        margin: '0px 0px 22px 0px !important',
        width: '100%',
        zIndex: '0'
    },
    helperText: {
        fontSize: '12px',
        backgroundColor: '#607d8b3d',
        padding: '5px',
        borderRadius: '3px'
    },
    selectControl: {
        height: '56px !important',
        zIndex: '999'
    }
}));


  



