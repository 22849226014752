import React, {useEffect, useState} from 'react';
import NewURLDocPlace from "./components/new/new";
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from "@mui/material/Button";
import {Container} from "reactstrap";
import MetaTags from "react-meta-tags";
import {TextButton, TextTitle} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {customers} from "../../store/customers/actions";
import {useNavigate} from "react-router-dom";

export function PaymentDocPlaceBf() {
    const [showNew, setShowNew] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataCustomers, setDataCustomers] = useState([]);

    const {responseCustomers, loadingCustomers} = useSelector((state) => ({
        errorCustomers: state.Customers.errorCustomers,
        responseCustomers: state.Customers.responseCustomers,
        loadingCustomers: state.Customers.loadingCustomers,
    }));

    useEffect(() => {
        if (responseCustomers && responseCustomers) {
            setDataCustomers(responseCustomers);
        }
    }, [responseCustomers]);

    useEffect(() => {
        if (!responseCustomers) {
            dispatch(customers({}, navigate))
        }
    }, [])
    return (
        <div>
            <MetaTags>
                <title>DocPlace Payment Back Office</title>
            </MetaTags>
            <Container fluid>
                <TextTitle>
                    Press the button to create a payment link
                </TextTitle>
                <Button
                    onClick={() => {
                        setShowNew(true)
                    }}
                    style={{marginTop: '20px'}}
                    size="large" color="primary"
                    variant="contained">
                    <AddBoxIcon/>
                    <TextButton>Generate new payment link</TextButton>
                </Button>
                <NewURLDocPlace
                    billingInformation={dataCustomers ? dataCustomers : []}
                    show={showNew}
                    close={() => {
                        setShowNew(false)
                    }
                    }
                />
            </Container>
        </div>
    )
}

export default PaymentDocPlaceBf
